<template>
  <div id="app">
    <PageFrame />
  </div>
</template>
<script>
import PageFrame from "./components/PageFrame"
export default {
  name : "App",
  components: {PageFrame},
}
</script>

<style lang="stylus">
  @import "styles/global.styl"
</style>