
const config = {
  NavBar : {
    NavRouteList: [
      {key:"home", title: "主页", url: "/#/"},
    ],
    OutsiteLinkList: [
      {key:"fl_zry", title: "ZRY的个人网站", url: "http://swzry.com/"},
    ],
  }
}
export default {
  config
}
