import { getIterator } from "core-js"

const C_OHF = () => import("@/views/Constructing.vue")
const C_YV8R0 = () => import("@/views/Constructing.vue")

const calcList = [
    {
        catkey: "basic", title: "基础计算", content: 
        [
            { key: "ohms", title: "欧姆定律相关计算"},
            { key: "voltdiv", title: "分压计算"},
            { key: "indcapres", title: "感抗/容抗计算"},
            { key: "lcreso", title: "LC震荡计算"},
            { key: "ftconv", title: "频率/时间换算"},
        ]
    },
/*     {
        catkey: "pcb", title: "PCB计算", content: 
        [
            { key: "tvcc", title: "铜线/过孔电流计算"},
        ]
    }, */
    {
        catkey: "power", title: "电源设计", content: 
        [
            { key: "duty", title: "占空比计算"},
        ]
    },
]

const inf = {
    getRouteList() {
        var crl = []
        calcList.forEach(iv => {
            iv.content.forEach(jv => {
                crl.push({
                    path: "/calc/" + iv.catkey + "/" + jv.key,
                    name: iv.catkey + "_" + jv.key,
                    component: () => import("@/views/v_calc/" + iv.catkey + "_" + jv.key + ".vue"),
                })
            })
        });
        return crl
    },

    getRenderList(current_url) {
        var rl = []
        var regex = /^\/calc\/([a-zA-Z0-9_]+)\/(.*)$/
        var prme = regex.exec(current_url)
        var grpname = prme ? prme[1] : "basic"
        calcList.forEach(iv => {
            var gil = []
            var gact = (grpname == iv.catkey)
            iv.content.forEach(jv => {
                var rtpath = "/calc/" + iv.catkey + "/" + jv.key
                var act = (rtpath == current_url)
                gil.push({
                    key: iv.catkey + "_" + jv.key,
                    path: rtpath,
                    active: act,
                    title: jv.title,
                })
            })
            var ir = {
                key: iv.catkey,
                accordion: "accordion-" + iv.catkey, 
                title: iv.title,
                content: gil,
                active: gact,
            }
            rl.push(ir)
        });
        return rl
    },

    getBreadcrumbData(current_url){
        var r = []
        r.push({text: "ZRY EE Calc", to: "/"})
        if(current_url == "/"){
            return r
        }
        var regex = /^\/calc\/([a-zA-Z0-9_]+)\/(.*)$/
        var prme = regex.exec(current_url)
        var grpname = prme ? prme[1] : ""
        var ccat = null
        var cpg = null
        calcList.forEach(iv =>{
            if(iv.catkey == grpname){
                ccat = iv
            }
        })
        if(typeof ccat == typeof {}){
            r.push({text: ccat.title, disabled: true})
            ccat.content.forEach(jv => {
                var rtpath = "/calc/" + ccat.catkey + "/" + jv.key
                if(rtpath == current_url){
                    cpg = jv
                }
            })
            if(typeof cpg == typeof {}){
                r.push({text: cpg.title, to: current_url})
            }
        }
        return r
    },
}

export default inf