import Vue from 'vue'
import Router from 'vue-router'

import CalcList from '@/datasrc/calc.list.js'

const Home = () => import("@/views/Home.vue")
const NotFound = () => import("@/views/NotFound.vue")

Vue.use(Router)

var routeList = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: "主页"
    },
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound,
    meta: {
      title: "Not Found"
    }
  },
]
routeList = routeList.concat(CalcList.getRouteList())
const router = new Router({
  routes: routeList,
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - ZRY EE Calc"
  }else{
    document.title = "ZRY EE Calc"
  }
  next()
})

export default router