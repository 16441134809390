import * as types from "./mutation_types.js"

const mutations = {
/*     [types.Mutation_setBodyBgColor](state, color){
        state.bodyBgColor = color
    } */
    [types.Mutation_addNavLink](state, linkobj){
        state.navBarLink.navList.push(linkobj)
    },
    [types.Mutation_addOutsiteLink](state, linkobj){
        state.navBarLink.outsiteLink.push(linkobj)
    }
}

export default mutations