import * as types from "./mutation_types.js"

/* export const setBodyBgColor = ({commit}, color) => {
    commit(types.Mutation_setBodyBgColor, color)
} */

export const addNavLink = ({commit}, linkobj) => {
    commit(types.Mutation_addNavLink, linkobj)
}

export const addOutsiteLink = ({commit}, linkobj) => {
    commit(types.Mutation_addOutsiteLink, linkobj)
}