import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./styles/theme.scss"
import GlobalConfig from './jsmods/GlobalConfig'
import BootstrapVue from 'bootstrap-vue'
import store from './vuex'

Vue.use(BootstrapVue)
Vue.config.productionTip = false

// Vue.prototype.$GLOBAL = GlobalConfig

GlobalConfig.config.NavBar.NavRouteList.forEach((item) => {
  store.dispatch("addNavLink", item)
})

GlobalConfig.config.NavBar.OutsiteLinkList.forEach((item) => {
  store.dispatch("addOutsiteLink", item)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
