<template>
  <b-navbar variant="tmfooter">
    <b-container>
      <p class="text-muted">
        Copyright &copy; 2019 ZRY. All rights reserved.
      </p>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: "FooterComp"
};
</script>

<style lang="stylus" scoped></style>