<template>
  <div class="s_page">
    <header class="s_header">
      <HeaderNav />
    </header>
    <section class="s_main">
      <ContentView />
    </section>
    <footer class="s_footer">
      <FooterComp />
    </footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterComp from "@/components/FooterComp";
import ContentView from "@/components/ContentView";
export default {
  name: "PageFrame",
  components: { FooterComp, HeaderNav, ContentView }
};
</script>

<style lang="stylus" scoped>
@import '../styles/frame.styl';

#img_navbar_logo {
  height: 48px;
}

#navbar_title_span {
  font-size: 24px;
}
</style>
