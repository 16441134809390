<template>
  <div>
    <b-row>
      <b-col md="2">
        <!--<b-nav vertical pills>
          <b-nav-item v-for="v in navList" :key="v.key" :active="v.isActive" :href="v.url" active-class="tmprimary">{{v.title}}</b-nav-item>
        </b-nav>-->
        <b-card no-body class="mb-1" v-for="cati in calcMenifest" :key="cati.key">
<!--           <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block href="#" v-b-toggle="cati.accordion" variant="info">{{cati.title}}</b-button>
          </b-card-header> -->
          <b-card-header header-tag="header" role="tab" header-bg-variant="secondary" header-text-variant="white" v-b-toggle="cati.accordion">
            {{cati.title}}
          </b-card-header>
          <b-collapse :id="cati.accordion" accordion="calc-menifest-accordion" role="tabpanel" :visible="cati.active">
            <b-list-group flush>
              <b-list-group-item :active="calcv.active" v-for="calcv in cati.content" :key="calcv.key" :to="calcv.path">
                {{calcv.title}}
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </b-card>
      </b-col>
      <b-col md="10">
        <br />
        <b-container fluid>
          <b-breadcrumb :items="breadcrumbData" />
          <router-view />
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CalcListInfo from "@/datasrc/calc.list.js";
export default {
  name: "home",
  methods: {
    ...mapActions(["setBodyBgColor"])
  },
  computed:{
    calcMenifest(){
      return CalcListInfo.getRenderList(this.$route.path);
    },
    breadcrumbData(){
      return CalcListInfo.getBreadcrumbData(this.$route.path);
    }
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#e9ecef");
  },
  beforeDestroy() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff");
  }
};
</script>